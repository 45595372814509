@keyframes Marquee {
  0% {
    left: 100%;
  }
  100% {
    left: 0%;
  }
}

html,
body {
  height: 100%;
}
#root {
  height: 100%;
}
.App {
  min-height: 100%;
}

.marquee-container {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.marquee {
  position: absolute;
  text-align: left;
  bottom: 0;
  white-space: nowrap;
  font-weight: bold;
  left: 100%;
}
.marquee.anim {
  /* animation: Marquee infinite 20s linear; */
  transition-property: left;
  transition-timing-function: linear;
}
